<template>

  <div style="display: flex; flex-direction: column; margin-bottom: 0.5rem;">
    <div class="d-inline-flex width-auto" style="cursor: pointer" v-on:click="clickProduct()">
      <div class="block-quantity mr-2 ml-3">
        <span>{{ parseFloat(product.quantity).toFixed(2) }}</span>
      </div>

      <label class="product-item d-flex align-items-center width-auto">
        <!-- {{ $t('views.client.details.body.left.subscription-affect.fees-rate') }} -->
        {{ product.name }}
      </label>
      <label class="width-auto product-item d-flex justify-content-end align-items-center">
        {{ parseFloat('' + (product.price * product.quantity)).toFixed(2) }} {{ $currency }}
      </label>
    </div>

    <div class="row-actions">
        <div v-if="showDelete" class="action-btn" v-on:click="deleteProduct()">
          <i class="fa fa-trash"></i>
          <span>{{ $t('views.client.details.body.left.subscription-affect.delete-product') }}</span>
        </div>
        <div v-if="showUpdate" class="action-btn" v-on:click="updatePrice()">
          <img
            :src="require('@/assets/icons/caisse/pricing.svg')"
          />
          <span>{{ $t('views.client.details.body.left.subscription-affect.update-price') }} {{ parseFloat($store.getters["nextore/getTapeValue"]).toFixed(2) }} €</span>
        </div>
        <div v-if="showUpdate" class="action-btn" v-on:click="updateQuantity()">
          <img
            class="pointer"
            width="20px"
            :src="require('@/assets/icons/caisse/quantity.svg')"
          />
          <span>{{ $t('views.client.details.body.left.subscription-affect.update-quantity') }} {{ parseFloat($store.getters["nextore/getTapeValue"]).toFixed(2) }}</span>
        </div>
    </div>

  </div>




</template>

<script>
  export default {
    data: () => ({
      showDelete: false,
      showUpdate: false,
      tapeValue: 0
    }),
    props: {
      index: {
        type: Number,
        default: null
      },
      product: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
      deleteProduct() {
        this.$store.dispatch("nextore/removeProduct", this.index);
        this.showUpdate = false;
        this.showDelete = false;
      },
      updatePrice() {
        this.$store.dispatch("nextore/updatePrice", this.index);
        this.showUpdate = false;
        this.showDelete = false;
      },
      updateQuantity() {
        this.$store.dispatch("nextore/updateQuantity", this.index);
        this.showUpdate = false;
        this.showDelete = false;
      },
      clickProduct() {
        if (this.showUpdate || this.showDelete) {
          this.showUpdate = false;
          this.showDelete = false;
        } else {
          const tapeValue = this.$store.getters["nextore/getTapeValue"];
          if (tapeValue !== null && tapeValue !== 0) {
            this.showUpdate = true;
          } else {
            this.showDelete = true;
          }
        }
      }
    }
  }
</script>
<style scoped lang="scss">

.width-auto {
  width: -webkit-fill-available;
}

.product-item {
  font: normal normal normal 15px Avenir;
  margin-bottom: 0;
}

.block-quantity {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  font-size: 25px;
  display: flex;
  align-items: center;
  background-color: rgb(240, 240, 243);
  background-image: none;
  --text-color: #727d9b;
  --text-font-size: 15px;
  span {
    width: 100%;
    text-align: center;
    vertical-align: middle;
    color: var(--text-color);
    font-size: 15px;
  }
}

.row-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .action-btn {
    cursor: pointer;
    width: 33%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    img {
      min-width: 30px;
      min-height: 30px;
      max-width: 30px;
      max-height: 30px;

    }
    label {
      cursor: pointer;
      text-align: center;
    }

    &:hover {
      cursor: pointer;
      border-radius: 10px;
      background-color: #F8F8FB;
    }
  }

}
</style>
